import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateSurveySearchFilterState } from 'redux/slices/surveys';
import { updateFilterState } from 'redux/slices/vehicleFaultSlice';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
import { IOption } from 'types/VehicleFaults';
import CheckBoxV2 from '../../atoms/CheckBoxV2';

export interface IInPageSearchCheckBoxGroup {
	filterName:
		| 'state'
		| 'branch'
		| 'vehicle'
		| 'priority'
		| 'status'
		| 'client'
		| 'assignee'
		| 'involved'
		| 'severity'
		| 'codeOfConduct'
		| 'incidentCategory'
		| 'lifeSavingRules'
		| 'correctiveActions'
		| 'restrictedInjury'
		| 'incidentAgency'
		| 'incidentMechanism'
		| 'correctiveActionsPriority'
		| 'incidentTimeQuadrant'
		| 'trafficControllersIntervened'
		| 'workCeasedUnsafeConditions'
		| 'mviCausation';
	filterType: 'incident' | 'fault' | 'survey';
	allCheckBox?: boolean;
	tidyTitles?: boolean;
}
const InPageSearchCheckBoxGroup: React.FC<IInPageSearchCheckBoxGroup> = ({
	filterName,
	filterType,
	allCheckBox,
	tidyTitles = false,
}) => {
	const dispatch = useAppDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);
	const surveyFilters = useAppSelector((state) => state.surveys.searchFilter);

	const getFilterState = useCallback(() => {
		let filterSection = filters;
		if (filterType === 'incident') {
			filterSection = incidentFilters;
		} else if (filterType === 'survey') {
			filterSection = surveyFilters;
		}
		return JSON.parse(JSON.stringify(filterSection));
	}, [filters, incidentFilters, surveyFilters, filterType]);

	const updateFilterStateByType = (filterState: { [key: string]: string }) => {
		if (filterType === 'fault') {
			dispatch(updateFilterState(filterState));
		}
		if (filterType === 'incident') {
			dispatch(updateIncidentFilterState(filterState));
		}
		if (filterType === 'survey') {
			dispatch(updateSurveySearchFilterState(filterState));
		}
	};

	const onChangeV2 = (id: number, checked: boolean) => {
		const filterState = getFilterState();

		const checkBoxItems = filterState[filterName] as IOption[];
		const index = checkBoxItems.findIndex((item) => item.id === Number(id));
		checkBoxItems[index].checked = !checked;
		filterState[filterName] = checkBoxItems;
		setIsAllChecked(false);

		updateFilterStateByType(filterState);
	};

	const [isAllChecked, setIsAllChecked] = useState<boolean>(true);
	const onChangeAll = (id: number, checked: boolean) => {
		const filterState = getFilterState();

		const checkBoxItems = filterState[filterName] as IOption[];
		for (const checkBoxItem of checkBoxItems) {
			checkBoxItem.checked = !checked;
		}
		filterState[filterName] = checkBoxItems;
		setIsAllChecked(!checked);

		updateFilterStateByType(filterState);
	};

	useEffect(() => {
		const filterState = getFilterState();
		const checkBoxItems = filterState[filterName] as IOption[];
		if (!checkBoxItems || checkBoxItems?.length < 1) {
			setIsAllChecked(false);
		} else {
			const isAllChecked = checkBoxItems.every((item) => item.checked);
			setIsAllChecked(isAllChecked);
		}
	}, [getFilterState, filterName]);

	return (
		<div className="grid gap-2 grid-col grid-cols-2 space-y-2 grid-flow-row justify-items-start">
			{allCheckBox && (
				<CheckBoxV2
					item={{
						checked: isAllChecked,
						id: 0,
						title: 'All',
						description: '',
					}}
					onChange={onChangeAll}
				/>
			)}
			<>
				{filterType === 'fault' &&
					(filters[filterName] as IOption[])?.map((item: IOption) => (
						<CheckBoxV2 key={item.id} item={item} onChange={onChangeV2} />
					))}
			</>
			<>
				{filterType === 'incident' &&
					(incidentFilters[filterName] as IOption[])?.map((item: IOption) => (
						<CheckBoxV2
							key={item.id}
							item={item}
							onChange={onChangeV2}
							tidyTitle={tidyTitles}
						/>
					))}
			</>
			<>
				{filterType === 'survey' &&
					(surveyFilters[filterName] as IOption[])?.map((item: IOption) => (
						<CheckBoxV2 key={item.id} item={item} onChange={onChangeV2} />
					))}
			</>
		</div>
	);
};

export default InPageSearchCheckBoxGroup;
