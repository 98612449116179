import {
	IOption,
	IQueryReportSection,
	IVehicleIncidentSection7,
	section7PriorityRadioButtons,
} from '../../../types/VehicleIncidents';
import React, { useEffect, useState } from 'react';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import {
	PlusIcon,
	TrashIcon,
	ChevronRightIcon,
} from '@heroicons/react/outline';
import { IMultiSelectComboBoxOption } from '../../atoms/MultiSelectComboBox/MultiSelectComboBox';
import TextareaInput from '../../atoms/TextareaInput';
import Text from '../../atoms/Text';
import ComboBox from '../../atoms/ComboBox';
import DateInput from '../../atoms/DateInput';
import ToggleButtonV2 from '../../atoms/ToggleButtonV2';
import Divider from '../../atoms/Divider';
import { hasAccess } from '../../../utils/permissions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { disableWriteAccess } from '../../../utils/disableWriteAccess';
import {
	useGetIncidentSection7Mutation,
	useUpdateIncidentSection7Mutation,
} from '../../../redux/api/vehicleIncidents';
import FormCheckBoxGroupV2 from '../../molecules/FormCheckboxGroupV2';
import RadioV2 from '../../atoms/RadioV2';
import ErrorSolid from '../../../assets/icons/ErrorSolid';
import { closeIncidentModal } from 'redux/slices/vehicleIncidentsSlice';
import LoaderSpinner from '../../atoms/LoaderSpinner';
import { debounce } from 'lodash';
import { formatDate } from 'utils/formatDate';

interface IAction {
	id?: number;
	action_description: string;
	hierachy_of_control: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	assignee: string;
	action_taken: string;
	completion_date: Date;
	completed: boolean;
	priority: string;
}

interface ISection7InitialState {
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	actions: IAction[];
}

const initialState: ISection7InitialState = {
	users: [],
	actions: [],
};

export interface IIncidentSection7 {
	sectionId: number | null;
	setSection: (section: IQueryReportSection) => void;
	errorMessages: string[];
	handleValidate: () => void;
	getIncidents: (filters: string, prepend: boolean) => void;
	filters: string;
	completed: boolean;
	savingIncident: boolean;
	setSavingIncident: (input: boolean) => void;
	readTabs: { name: string; isRead: boolean }[];
	setReadTabs: (input: { name: string; isRead: boolean }[]) => void;
	iCamIsVisible: boolean;
}

const IncidentSection7: React.FC<IIncidentSection7> = ({
	sectionId,
	setSection,
	handleValidate,
	errorMessages,
	getIncidents,
	filters,
	completed,
	savingIncident,
	setSavingIncident,
	readTabs,
	setReadTabs,
	iCamIsVisible,
}) => {
	const [getIncidentSection7, { data, isSuccess, isLoading }] =
		useGetIncidentSection7Mutation();
	const [section7, setSection7] = useState(initialState); //TODO: uncomment when porting
	const [updateIncidentSection7, { isLoading: saving }] =
		useUpdateIncidentSection7Mutation(); //TODO: uncomment when porting
	const userPermission = useAppSelector((state) => state.user.permissions);
	const incidentSection1Id = useAppSelector(
		(state) => state.vehicleIncident.incidentSection1Id
	);
	const incidentBranchIdGlobalState = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const [incidentBranchId, setIncidentBranchId] = useState(
		incidentBranchIdGlobalState
	);
	const incidentReportId = useAppSelector(
		(state) => state.vehicleIncident.incidentReportId
	);
	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);
	const investigatorHashTable: { [key: string]: boolean } = {};
	const investigatorOptions = section7.users
		.filter((user) => {
			return investigatorHashTable.hasOwnProperty(user.id)
				? false
				: (investigatorHashTable[user.id] = true);
		})
		.sort((a, b) => {
			return a.label > b.label ? 1 : -1;
		});
	const dispatch = useAppDispatch();

	const mapActionsFromBackend = (actions: any) => {
		return actions.map((action: any) => {
			return {
				id: action.id,
				action_description: action.action_description,
				action_taken: action.action_taken,
				completed: action.completed,
				completion_date: new Date(action.completion_date),
				priority: action.priority,
				assignee:
					typeof action.assignee !== 'number' && action.assignee
						? `${action.assignee?.first_name} ${action.assignee?.last_name}`
						: '',
				hierachy_of_control: action.hierachy_of_control.map((hoc: any) => ({
					id: hoc.id || 0,
					title: hoc.label,
					checked: hoc.is_selected,
				})),
			};
		});
	};

	useEffect(() => {
		if (saving) {
			setSavingIncident(true);
		} else {
			setSavingIncident(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saving]);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection7(sectionId);
		}
	}, [getIncidentSection7, sectionId]);

	useEffect(() => {
		if (isSuccess && data) {
			const { branch } = data;
			const { users, actions } = data.data;

			let formatActions: any[] = [];

			if (actions) {
				formatActions = mapActionsFromBackend(actions);
			}

			if (branch) {
				setIncidentBranchId(branch.id.toString());
			}

			setSection7({
				...section7,
				users,
				actions: formatActions,
			});
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleOnBlur = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		await updatePayload(false);
	};

	const handleSection7Update = (
		property: string,
		value: string | boolean | Date | IOption[],
		index?: number,
		disableUpdateRequest?: boolean
	) => {
		const newSection7 = section7;

		if (index !== undefined) {
			const newActions = [...section7.actions];
			newActions[index] = {
				...newActions[index],
				[property]: value,
			};

			newSection7.actions = newActions;
			setSection7(newSection7);
		} else {
			// @ts-ignore
			newSection7[property] = value;
			setSection7(newSection7);
		}

		if (!disableUpdateRequest) {
			handleOnBlur();
		}
	};

	const updatePayloadRaw = async (
		isReportCompleted: boolean = false,
		newActions?: IAction[]
	) => {
		const { actions } = section7;

		const formattedActions: IVehicleIncidentSection7['actions'] = [];
		const tempActions = newActions ? [...newActions] : [...actions];
		for (const tempAction of tempActions) {
			let selectedPriority = tempAction.priority;
			if (Number.isInteger(parseInt(selectedPriority))) {
				section7PriorityRadioButtons.forEach((item) => {
					if (item.id === tempAction.priority) {
						selectedPriority = item.title;
					}
				});
			}
			// Update Assignee and Hoc payload
			const assignee =
				Number(
					section7.users.find((user) => user.label === tempAction.assignee)?.id
				) || undefined;
			const hierachy_of_control = tempAction.hierachy_of_control.map((hoc) => ({
				label: hoc.title,
				name: hoc.title.toLowerCase(),
				is_selected: hoc.checked,
			}));
			const completedDate = formatDate(tempAction.completion_date);
			const newAction = {
				...tempAction,
				assignee,
				completed_date: completedDate,
				hierachy_of_control,
				priority: selectedPriority,
			};

			// To Remove duplicate
			/*
			const isExist = formattedActions.find(
				(action) =>
					action.action_description === newAction.action_description &&
					action.action_taken === newAction.action_taken &&
					action.assignee === newAction.assignee &&
					action.completed === newAction.completed
			);
			if (!isExist) {
				formattedActions.push(newAction);
			}
			*/

			formattedActions.push(newAction);
		}

		const payload: IVehicleIncidentSection7 = {
			id: sectionId,
			section1Id: incidentSection1Id,
			users: section7.users,
			actions: formattedActions,
			incidentReportId,
		};

		if (isReportCompleted) {
			payload.isReportCompleted = true;
		}

		/* This is creating more issues than it solves for the moment - Ronnie
		await updateIncidentSection7(payload)
			.unwrap()
			.then((data: any) => {
				if (data) {
					setSection7((section7) => {
						return {
							...section7,
							actions: mapActionsFromBackend(data.actions),
						};
					});

					return new Promise((resolve) => {
						resolve(data);
					});
				}
			}); //TODO uncomment later
		*/

		await updateIncidentSection7(payload);
	};

	const updatePayload = debounce(updatePayloadRaw, 500);

	const addAction = async () => {
		if (disableIncidentWriteAccess) return;

		const addAction = [
			...section7.actions,
			{
				action_description: '',
				hierachy_of_control: [
					{
						id: -1,
						title: 'Elimination',
						checked: false,
					},
					{
						id: -2,
						title: 'Substitution',
						checked: false,
					},
					{
						id: -3,
						title: 'Isolation',
						checked: false,
					},
					{
						id: -4,
						title: 'Engineering',
						checked: false,
					},
					{
						id: -5,
						title: 'Administration',
						checked: false,
					},
					{
						id: -6,
						title: 'PPE',
						checked: false,
					},
				],
				assignee: '',
				action_taken: '',
				completion_date: new Date(),
				completed: false,
				priority: 'Low',
			},
		];

		setSection7((section7) => {
			return {
				...section7,
				actions: addAction,
			};
		});

		await updatePayload(false, addAction);
	};

	const isCorrectiveActionsDeleteDisabled = () => {
		return !hasAccess(
			userPermission,
			'admin-manager',
			undefined,
			undefined,
			incidentBranchId
		);
	};

	const deleteAction = async (id: number | undefined) => {
		if (disableIncidentWriteAccess) return;

		const filteredList = section7.actions.filter((action) => action.id !== id);
		setSection7((prevSection7) => {
			return {
				...prevSection7,
				actions: filteredList,
			};
		});

		updatePayload(false, filteredList);
	};

	const handleCreateIncidentSection7 = async () => {
		if (!sectionId) {
			return;
		}
		updatePayloadRaw(true).then(() => {
			getIncidents(filters, true);
			dispatch(closeIncidentModal());
		});
	};

	return (
		<>
			{isLoading || !data ? (
				<LoaderSpinner />
			) : (
				<div>
					<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">
						Corrective Actions
					</Heading>
					{section7.actions.map((action, index) => (
						<div className="!mt-12 !mb-12 space-y-8 py-[24px] px-[40px] border-solid border-[1px] bg-[#FEEDD6] rounded-md">
							<div className="flex items-center justify-between space-x-4">
								<Heading type="h2" className="uppercase">
									Corrective Action {index + 1}
								</Heading>
								<Button
									type="quinary"
									className={`font-bold !rounded-3xl px-[24px] py-[10px] tracking-[1.92px] uppercase ${
										isCorrectiveActionsDeleteDisabled() ? 'hidden' : 'font-bold'
									}`}
									onClick={() => deleteAction(action.id)}
									size="md"
									isDisabled={isCorrectiveActionsDeleteDisabled()}
								>
									<TrashIcon
										height={20}
										width={18}
										className="cursor-pointer"
									/>
									&nbsp;Delete
								</Button>
							</div>
							<Text
								type="bold"
								className="font-inter leading-[26px] tracking-[-1px] text-secondary-900 flex"
							>
								Priority
								<Text className="ml-2 text-red">*</Text>
							</Text>
							<div className="gap-2 w-full">
								<RadioV2
									options={section7PriorityRadioButtons}
									onChange={(value: string) => {
										handleSection7Update('priority', value, index);
									}}
									onBlur={handleOnBlur}
									defaultId={
										section7PriorityRadioButtons.find(
											(priority) => priority.title === action.priority
										)?.id
									}
									className="mt-2 mb-2 text-sm"
									classNameWrapper="gap-4"
									radioDirection={'grid'}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="flex flex-row">
								<TextareaInput
									id="5"
									className="w-full"
									value={action.action_description}
									rows={10}
									label={`Action`}
									required={true}
									placeholder=""
									resize={true}
									onChange={(value: string) =>
										handleSection7Update(
											'action_description',
											value,
											index,
											true
										)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="w-full">
								<Text
									type="bold"
									className="font-inter leading-[26px] tracking-[-1px] text-secondary-900 pb-2 flex"
								>
									Hierachy of Control
									<Text className="ml-2 text-red">*</Text>
								</Text>
								<FormCheckBoxGroupV2
									checkBoxName="hierachyControl"
									checkBoxGroupData={action.hierachy_of_control}
									onHandleChange={(name, value) =>
										handleSection7Update('hierachy_of_control', value, index)
									}
									descriptionDirection="flex-row"
									gridColumns={'4'}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="flex flex-row">
								<TextareaInput
									id="6"
									className="w-full"
									value={action.action_taken}
									rows={10}
									label={`Action Taken`}
									placeholder=""
									resize={true}
									onChange={(value: string) =>
										handleSection7Update('action_taken', value, index, true)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div>
								<ComboBox
									label="Assigned to"
									options={investigatorOptions}
									isDisabled={disableIncidentWriteAccess}
									required={true}
									onChange={(value: string) =>
										handleSection7Update('assignee', value, index)
									}
									selected={action.assignee}
									className="mb-10 w-80"
								/>
							</div>
							<div>
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection7Update('completion_date', value, index)
									}
									className="w-72"
									label={`Completion Date`}
									iconClassName="text-black"
									selected={action.completion_date}
									wrapperClassName="react-datepicker-margin-0"
									isDisabled={disableIncidentWriteAccess}
									required={true}
								/>
							</div>
							<div>
								<ToggleButtonV2
									toggle={action.completed}
									onToggle={(value: boolean) =>
										handleSection7Update('completed', value, index)
									}
									label={`Completed`}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						</div>
					))}
					<Button
						onClick={addAction}
						type="secondary"
						className="font-bold mr-4 mt-[32px] !rounded-3xl px-[24px] py-[10px] tracking-[1.92px]"
						isDisabled={disableIncidentWriteAccess}
					>
						Add Action
						<PlusIcon height={18} width={18} className="ml-2" />
					</Button>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					{errorMessages.length > 0 && (
						<>
							<div className="w-full border-[#DC3F4F] border-solid border-[2px] rounded-[8px] p-[8px] bg-red-200">
								<div className="flex flex-row gap-[8px] pb-3 ">
									<div className="flex flex-col justify-center">
										<ErrorSolid color={'#DC3F4F'} />
									</div>
									<Heading type="h3" className="text-red-500">
										Missing details
									</Heading>
								</div>
								<Text color="text-red-500">
									Please complete the missing details to generate this report in
									sections:
								</Text>
								{errorMessages.map((error, index) => {
									return (
										<li
											key={index}
											className="text-red-500 font-inter font-normal tracking-normal first-letter:capitalize"
										>
											{error}
										</li>
									);
								})}
							</div>
							<Divider
								className="my-[64px]"
								color="!border-t-[4px] border-orange-500"
							/>
						</>
					)}
					<div className="flex justify-between mb-[64px]">
						{iCamIsVisible ? (
							<Button
								onClick={() => {
									let temp = readTabs;
									temp[6].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section6');
								}}
								isDisabled={savingIncident}
								type="primary"
								className="font-bold !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
							>
								Back to ICAM
							</Button>
						) : (
							<Button
								onClick={() => {
									let temp = readTabs;
									temp[6].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section5');
								}}
								isDisabled={savingIncident}
								type="primary"
								className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
							>
								Back to Investigation
							</Button>
						)}
						<Button
							onClick={handleCreateIncidentSection7}
							className="font-bold !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
							isDisabled={
								errorMessages.length > 0 ||
								disableIncidentWriteAccess ||
								savingIncident
							}
						>
							{completed ? 'Save' : 'Create Report'}
							<ChevronRightIcon height={18} width={32} />
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default IncidentSection7;
