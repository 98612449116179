import { IFilters, IOption } from 'types/VehicleFaults';

export const filtersToQueryStringConverter = (filters: IFilters) => {
	let queryString = '';

	for (const [key, value] of Object.entries(filters)) {
		if (
			Array.isArray(value) &&
			value.filter((item) => item.checked === true).length > 0
		) {
			let subQuery = `${key}=`;
			value.forEach((filter: IOption) => {
				if (filter.checked) subQuery = subQuery.concat(`${filter.title},`);
			});
			subQuery = subQuery.substring(0, subQuery.length - 1);
			queryString = queryString.concat(subQuery + '&');
		} else if (
			!Array.isArray(value) &&
			value !== null &&
			(value as string).length > 0
		) {
			queryString = queryString.concat(`${key.toLocaleLowerCase()}=${value}&`);
		}
	}
	return encodeURI(queryString);
};
